import { CssBaseline } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import packageJson from '../package.json';
import App from './App';
import './App.css';
import { AuthProvider } from './context/AuthContext';
import { SearchProvider } from './context/SearchContext';
import ComponentsOverrides from './theme/overrides';

const ROUTER_BASENAME = packageJson.homepage ?? '/';

const client = new QueryClient({
	defaultOptions: {
		queries: {
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			refetchInterval: 1000 * 60 * 30, // 30 minutes
			refetchIntervalInBackground: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			staleTime: 1000 * 60 * 30, // 30 minutes
			cacheTime: 1000 * 60 * 30, // 30 minutes
		},
	},
});

const theme = createTheme();
theme.components = ComponentsOverrides(theme);
theme.palette = {
	...theme.palette,
	primary: {
		main: '#1585D2',
		light: '#1585D2',
		dark: '#1585D2',
		contrastText: 'white',
	},
	secondary: {
		main: '#434D56',
		light: '#434D56',
		dark: '#434D56',
		contrastText: 'white',
	},
	error: {
		main: '#E21E19',
		light: '#E21E19',
		dark: '#E21E19',
		contrastText: 'white',
	},
};
theme.typography = {
	...theme.typography,
	fontFamily: ['Nunito', 'sans-serif'].join(','),
	h2: {
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem',
		},
		fontFamily: 'Nunito',
		// font: 'normal normal bold 50px/5px Nunito',
		fontSize: '40px',
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
	h4: {
		[theme.breakpoints.down('md')]: {
			fontSize: '1.3rem',
		},
		fontFamily: 'Nunito',
		// font:'normal normal normal 40px/25px Nunito',
		fontSize: 24,
		color: '#434D56',
	},
	subtitle2: {
		fontFamily: 'Nunito',
		fontSize: 15,
		color: '#434D56',
	},
	body1: {
		fontFamily: 'Nunito',
		fontSize: 14,
		fontWeight: 'normal normal bold',
		color: '#434D56',
	},
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<AuthProvider>
			<BrowserRouter basename={ROUTER_BASENAME}>
				<QueryClientProvider client={client}>
					<StyledEngineProvider injectFirst>
						<MUIThemeProvider theme={theme}>
							<SearchProvider>
								<CssBaseline />
								<App />
							</SearchProvider>
						</MUIThemeProvider>
					</StyledEngineProvider>
					{process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
				</QueryClientProvider>
			</BrowserRouter>
		</AuthProvider>
		,
	</React.StrictMode>
);
