import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Theme } from '@mui/material';

function CheckboxIcon() {
	return (
		<Box
			sx={{
				width: '18px',
				height: '18px',
				background: 'white',
				border: '1px solid #CED0D4',
				borderRadius: '6px',
			}}
		/>
	);
}

const checkedCheckboxStyles = {
	width: '18px',
	height: '18px',
	backgroundColor: '#1585D2',
	border: '1px solid #CED0D4',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '6px',
};

function CheckboxCheckedIcon() {
	return (
		<Box sx={checkedCheckboxStyles} p={0.5}>
			<svg xmlns="http://www.w3.org/2000/svg" width="17.63" height="14.135" viewBox="0 0 17.63 14.135">
				<path
					id="Path_2731"
					data-name="Path 2731"
					d="M-976-8373.882l5.332,5.266,8.074-10.531"
					transform="translate(978.121 8381.251)"
					fill="none"
					stroke="#fff"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="3"
				/>
			</svg>
		</Box>
	);
}

function CheckboxIntermediateIcon() {
	return (
		<Box sx={checkedCheckboxStyles} p={0.5}>
			<RemoveIcon sx={{ padding: '0 5px', color: 'white' }} />
		</Box>
	);
}

export const Checkbox = (theme: Theme) => {
	return {
		MuiCheckbox: {
			defaultProps: {
				icon: <CheckboxIcon />,
				checkedIcon: <CheckboxCheckedIcon />,
				indeterminateIcon: <CheckboxIntermediateIcon />,
			},
			styleOverrides: {
				root: {
					padding: theme.spacing(1),
					borderRadius: '4px',
					'&.Mui-checked.Mui-disabled, &.Mui-disabled': {
						color: theme.palette.action.disabled,
					},
					'& .MuiSvgIcon-fontSizeMedium': {
						width: 22,
						height: 22,
					},
					'& .MuiSvgIcon-fontSizeSmall': {
						width: 18,
						height: 18,
					},
					svg: {
						fontSize: 22,
						'&[font-size=small]': {
							fontSize: 18,
						},
					},
				},
				// colorPrimary: {

				// }
			},
		},
	};
};
