import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Container, IconButton, Typography, useMediaQuery } from '@mui/material';
import { decode } from 'html-entities';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { HighlightedGame } from '../../types';
import { formatFullMonthDate } from '../../utils';

declare const window: any;

type Props = {
	upcomingGames: Array<HighlightedGame>;
};

const HighlightGamesCarousel: React.FC<Props> = ({ upcomingGames }) => {
	const [swipe, setSwipe] = useState<any>();
	const isTablet = useMediaQuery('(max-width:800px)');

	const IMAGE_HEIGHTS = [220, 220, 240, 250, 250];

	const navigate = useNavigate();
	const moveToFilters = (gameName: string) => {
		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'homepage_item_click',
				eventProps: {
					homepage_clicked_section: 'Highlighted Games Carousel',
				},
			});
		}
		navigate({ pathname: '/game-library', search: `?gameName=${gameName.replace('’', "'")}` });
	};

	const buttonStyles = {
		background: 'white',
		borderRadius: '20%',
		boxShadow: '0px 0px 10px rgba(0,0,0,0.4)',
		'&:hover': {
			background: 'rgba(255,255,255,0.9)',
			boxShadow: '0px 0px 10px rgba(0,0,0,0.6)',
		},
		position: 'absolute',
		zIndex: 999,
		transform: 'translate(0%, -50%)',
		top: '50%',
	};

	const ImagePlaceHolder: React.FC<{ title: string; releaseDate?: number; vendorText?: string }> = ({
		title,
		releaseDate,
		vendorText,
	}) => {
		return (
			<Box
				sx={{
					backgroundColor: '#1585D2',
					borderRadius: '30px',
					height: IMAGE_HEIGHTS,
					width: '100%',
					maxWidth: IMAGE_HEIGHTS,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					position: 'relative',
					overflow: 'hidden',
					backgroundImage: `url('/images/pariplay-monogram.svg')`,
					backgroundRepeat: 'no-repeat',
					backgroundPosition: '-39% 144%',
					backgroundSize: '64%',
				}}
			>
				{(releaseDate || vendorText) && (
					<Box
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							background:
								'transparent linear-gradient(0deg, #00000000 0%, #000000 85%) 0% 0% no-repeat padding-box',
						}}
						height={125}
					/>
				)}
				<ReleaseAndVendorDetails placeholder releaseDate={releaseDate} vendorText={vendorText} />
				<Typography
					fontSize="18px"
					color="white"
					textAlign="center"
					px="20px"
					component="div"
					fontWeight="bold"
				>
					{title}
				</Typography>
			</Box>
		);
	};

	const gamesButtonDisplay = useCallback(() => {
		return [
			upcomingGames?.length > 1 ? 'block' : 'none',
			upcomingGames?.length > 4 ? 'block' : 'none',
			upcomingGames?.length > 4 ? 'block' : 'none',
			upcomingGames?.length > 4 ? 'block' : 'none',
			upcomingGames?.length > 4 ? 'block' : 'none',
		];
	}, [upcomingGames]);

	const ReleaseAndVendorDetails: React.FC<{ releaseDate?: number; vendorText?: string; placeholder?: boolean }> = ({
		releaseDate,
		vendorText,
		placeholder,
	}) => {
		if (releaseDate || vendorText) {
			return (
				<>
					{releaseDate && (
						<Box
							sx={{
								position: 'absolute',
								top: '12px',
								left: '5px',
								minHeight: '25px',
								display: 'flex',
								alignItems: 'center',
							}}
							pl={2}
						>
							<Typography fontSize="small" color={'white'}>
								{formatFullMonthDate(new Date(releaseDate))}
							</Typography>
						</Box>
					)}
					{vendorText && (
						<Box
							sx={{
								position: 'absolute',
								top: '12px',
								right: '21px',
								minHeight: '25px',
								display: 'flex',
								alignItems: 'center',
							}}
							pl={2}
						>
							<Typography fontSize="small" color={'white'}>
								{vendorText}
							</Typography>
						</Box>
					)}
				</>
			);
		} else return null;
	};
	return (
		<Box sx={{ margin: { xs: '20px 0', md: '40px 0' } }}>
			<Container sx={{ maxWidth: '1100px !important' }}>
				<Typography sx={{ marginBottom: { xs: '10px', lg: '30px' } }} variant="h4">
					Newest Highlighted Games
				</Typography>
				<Box position={'relative'}>
					<Box sx={{ display: gamesButtonDisplay() }}>
						<IconButton
							onClick={() => swipe?.slidePrev()}
							sx={{ ...buttonStyles, left: isTablet ? '10px' : '-20px' }}
						>
							<KeyboardArrowLeft />
						</IconButton>
						<IconButton
							disabled={swipe?.isEnd}
							onClick={() => {
								swipe?.slideNext();
							}}
							sx={{ ...buttonStyles, right: isTablet ? '10px' : '-20px' }}
						>
							<KeyboardArrowRight />
						</IconButton>
					</Box>
					<Box>
						<Swiper
							spaceBetween={20}
							slidesPerView={1}
							onBeforeInit={(swipper) => setSwipe(swipper)}
							breakpoints={{
								'0': {
									slidesPerView: 1,
									spaceBetween: 0,
								},
								'500': {
									slidesPerView: 2,
									spaceBetween: 10,
								},
								'740': {
									slidesPerView: 3,
									spaceBetween: 10,
								},
								// '999': {
								// 	slidesPerView: 4,
								// 	spaceBetween: 20,
								// },
								'1055': {
									slidesPerView: 4,
									spaceBetween: 10,
								},
								// '1399': {
								// 	slidesPerView: 6,
								// 	spaceBetween: 20,
								// },
								// '1500': {
								// 	slidesPerView: 4,
								// 	spaceBetween: 25,
								// },
								// '1750': {
								// 	slidesPerView: 4,
								// 	spaceBetween: 25,
								// },
								// '2000': {
								// 	slidesPerView: 4,
								// 	spaceBetween: 25,
								// },
								// '2250': {
								// 	slidesPerView: 6,
								// 	spaceBetween: 20,
								// },
								// '2500': {
								// 	slidesPerView: 9,
								// 	spaceBetween: 20,
								// },
							}}
						>
							{upcomingGames?.map(({ title, image, releaseDate, vendorText }, id) => {
								const _decodedTitle = decode(title);

								return (
									<SwiperSlide onClick={() => moveToFilters(_decodedTitle)} key={id}>
										<Box
											sx={{
												padding: '20px 0',
												'&:hover': {
													transform: 'translateY(-12px)',
													transition: 'transform 0.2s ease-in-out',
												},
												cursor: 'pointer',
												justifyContent: 'center',
											}}
											position="relative"
											display="flex"
											width="100%"
											height="100%"
										>
											{image ? (
												<Box
													position="relative"
													sx={{
														height: IMAGE_HEIGHTS,
														width: '100%',
														maxWidth: IMAGE_HEIGHTS,
														borderRadius: '30px',
														overflow: 'hidden',
													}}
												>
													{(releaseDate || vendorText) && (
														<Box
															sx={{
																position: 'absolute',
																top: 0,
																left: 0,
																right: 0,
																background:
																	'transparent linear-gradient(0deg, #00000000 0%, #000000 85%) 0% 0% no-repeat padding-box',
															}}
															height={125}
														/>
													)}
													<img src={image} width="100%" height="100%" alt={_decodedTitle} />
													<ReleaseAndVendorDetails
														releaseDate={releaseDate}
														vendorText={vendorText}
													/>
												</Box>
											) : (
												<ImagePlaceHolder
													title={_decodedTitle}
													releaseDate={releaseDate}
													vendorText={vendorText}
												/>
											)}
										</Box>
									</SwiperSlide>
								);
							})}
						</Swiper>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default HighlightGamesCarousel;
