import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useContext } from 'react';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { fetchHomepage } from '../api';
import BottomLongCarousel from '../components/Home/BottomLongCarousel';
import HighlightGamesCarousel from '../components/Home/HighlightGamesCarousel';
import VendorsSlider from '../components/Home/VendersSlider';
import AuthContext from '../context/AuthContext';

const Home: React.FC = () => {
	const { state } = useContext(AuthContext);
	const { isLoading, data: homepageData } = useQuery(
		['homepageData'],
		({ signal }) => fetchHomepage(state.token, signal),
		{ enabled: !!state.token }
	);

	return (
		<Box>
			<Box height={'94px'} />
			<Box
				sx={{
					backgroundImage: `'url(${process.env.PUBLIC_URL}/images/home-background.png)'`,
					backgroundPosition: 'top',
					backgroundRepeat: 'no-repeat',
					height: 'calc( 100vh - 94px )',
					padding: '30px 0',
					overflowX: 'hidden',
					overflowY: 'scroll',
					// overflow: 'hidden',
				}}
				// height='100vh'
			>
				<Container sx={{ maxWidth: '1100px !important' }}>
					<Typography textAlign="left" sx={{ margin: { xs: '10px 0', lg: '30px 0' } }} variant="h2">
						Welcome to our Client Area
					</Typography>
				</Container>
				{isLoading && (
					<Box display="flex" justifyContent="center" alignItems="center" height={'100%'} width="100%">
						<CircularProgress />
					</Box>
				)}
				{(homepageData?.highlightedGames || []).length > 0 && (
					<HighlightGamesCarousel upcomingGames={homepageData?.highlightedGames || []} />
				)}
				{(homepageData?.vendorUpdates || []).length > 0 && (
					<VendorsSlider vendors={homepageData?.vendorUpdates} />
				)}
				{(homepageData?.promotions || []).length > 0 && (
					<VendorsSlider vendors={homepageData?.promotions} promotions />
				)}
				{(homepageData?.banners || []).length > 0 && (
					<BottomLongCarousel banners={homepageData?.banners || []} />
				)}
			</Box>
		</Box>
	);
};

export default Home;
