import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import AuthContext from './context/AuthContext';
import Router from './routes';

import TagManager from 'react-gtm-module';
import { useLocalStorage } from 'usehooks-ts';

declare const window: any;

const App: React.FC = () => {
	const [email] = useLocalStorage('_email', '');
	const [companyName] = useLocalStorage('_company_name', '');
	const location = useLocation();
	const {
		state: { loading: authLoading },
	} = useContext(AuthContext);

	// Google Tag Manager init
	if (location.pathname !== '/login' && !email.endsWith('@pariplaydev.com') && !email.endsWith('@pariplayltd.com')) {
		const tagManagerArgs = {
			gtmId: 'GTM-KW795TH',
			dataLayer: {
				user_email: email,
				user_company: companyName,
			},
		};
		TagManager.initialize(tagManagerArgs);
		TagManager.dataLayer(tagManagerArgs);
	}

	useEffect(() => {
		let pageTitle = 'Pariplay Client Area';
		switch (location.pathname) {
			case '/':
				pageTitle = 'Home | ' + pageTitle;
				break;
			case '/game-library':
				pageTitle = 'Game Library | ' + pageTitle;
				break;
			case '/master-game-list':
				pageTitle = 'Master Game List | ' + pageTitle;
				break;
			case '/roadmap':
				pageTitle = 'Roadmap | ' + pageTitle;
				break;
			case '/login':
				pageTitle = 'Login | ' + pageTitle;
				break;
		}
		document.title = pageTitle;
		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'pageview',
			});
		}
	}, [location.pathname]);

	return (
		<React.Fragment>
			{!authLoading && location.pathname !== '/login' && <Navbar />}
			<Router />
		</React.Fragment>
	);
};

export default App;
