import CloseIcon from '@mui/icons-material/Close';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SearchIcon from '@mui/icons-material/Search';
import {
	Box,
	Grid,
	IconButton,
	InputAdornment,
	Stack,
	SwipeableDrawer,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Theme } from '@mui/system';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDebounce, useLocalStorage } from 'usehooks-ts';
import AuthContext from '../../context/AuthContext';
import SearchContext from '../../context/SearchContext';
import { PAGE_PATH } from '../../routes/paths';

const NAV_MENU_ITEMS = [
	{
		name: 'Home',
		path: '/',
	},
	{
		name: 'Game Library',
		path: PAGE_PATH.gameLibrary,
	},
	{
		name: 'Master Game List',
		path: PAGE_PATH.masterGameList,
	},
	{
		name: 'Roadmap',
		path: PAGE_PATH.roadmap,
	},
];

const NavLinks: React.FC<{ pathname: string; theme: Theme }> = ({ pathname, theme }) => {
	const { dispatch } = useContext(AuthContext);
	const [, setToken] = useLocalStorage('_token', '');

	const handleLogout = () => {
		setToken('');
		dispatch({ type: 'REMOVE_TOKEN' });
	};

	return (
		<nav>
			<Stack
				direction={{ xs: 'column', lg: 'row' }}
				spacing={{ xs: 2 }}
				justifyContent="space-around"
				alignItems={{ xs: 'start', lg: 'center' }}
				sx={{
					paddingTop: {
						md: 3,
						lg: 0,
					},
					paddingLeft: {
						xl: 5,
					},
				}}
			>
				{NAV_MENU_ITEMS.map(({ name, path }) => (
					<Box key={name}>
						<Link style={{ textDecoration: 'none' }} to={path}>
							<Typography
								variant="subtitle2"
								color={pathname === path ? theme.palette.primary.main : theme.palette.secondary.main}
							>
								{name}
							</Typography>
						</Link>
					</Box>
				))}
				<Box style={{ cursor: 'pointer' }} onClick={handleLogout}>
					<Typography variant="subtitle2" color="#fe3439">
						Logout
					</Typography>
				</Box>
			</Stack>
		</nav>
	);
};

const Searchbar: React.FC = () => {
	const [search, setSearch] = useState('');
	const debouncedSearch = useDebounce(search, 500);
	const { state, dispatch } = useContext(SearchContext);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch({ type: 'SEARCH', payload: { searchQuery: debouncedSearch.trim() } });
	}, [debouncedSearch, dispatch]);

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === 'Enter') {
				if (location.pathname !== '/game-library') {
					navigate('/game-library');
				}
			}
		},
		[location.pathname, navigate]
	);

	useEffect(() => {
		if (state.searchQuery) {
			setSearch(state.searchQuery);
		}
	}, [state.searchQuery]);

	return (
		<TextField
			fullWidth
			type="text"
			size="small"
			placeholder="Search"
			value={search}
			onChange={handleSearch}
			onKeyDown={handleKeyDown}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				),
			}}
		/>
	);
};

const Navbar: React.FC = () => {
	const { pathname } = useLocation();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const isMobile = useMediaQuery('(max-width:1350px)');
	const theme = useTheme();

	useEffect(() => {
		setDrawerOpen(false);
	}, [pathname]);

	return (
		<Box
			sx={{
				padding: '13px 39px',
				backgroundColor: '#FFFFFF',
				boxShadow: '0px 4px 16px #A7AFB754',
				position: 'fixed',
				zIndex: 999,
				top: 0,
				left: 0,
				right: 0,
			}}
		>
			<Grid container justifyContent={'space-between'} alignItems="center">
				<Grid item xs={2.5} alignContent="center">
					<a
						style={{ textDecoration: 'none' }}
						href="http://pariplayltd.com/"
						target="_blank"
						rel="noreferrer"
					>
						<img
							src={`${process.env.PUBLIC_URL}/images/pariplay-logo.svg`}
							alt="Pariplay logo"
							width="187px"
							height="61px"
						/>
					</a>
				</Grid>
				{isMobile ? (
					<Grid item>
						<IconButton onClick={() => setDrawerOpen(true)}>
							<MenuOpenIcon fontSize="large" />
						</IconButton>
					</Grid>
				) : (
					<Grid item xs={9.5}>
						<Grid container alignItems="center" spacing={3}>
							<Grid item xs={5} xl={6}>
								<Searchbar />
							</Grid>
							<Grid item xs={7} xl={6}>
								<NavLinks pathname={pathname} theme={theme} />
							</Grid>
						</Grid>
					</Grid>
				)}

				{isMobile && (
					<SwipeableDrawer
						anchor="right"
						open={drawerOpen}
						onClose={() => setDrawerOpen(false)}
						onOpen={() => setDrawerOpen(true)}
					>
						<Box p={4}>
							<Stack spacing={4}>
								<Grid container alignItems="center" justifyContent="space-between">
									<Grid item>
										<img
											src={`${process.env.PUBLIC_URL}/images/pariplay-logo.svg`}
											alt="Pariplay logo"
											width="187px"
											height="61px"
										/>
									</Grid>
									<Grid item>
										<IconButton onClick={() => setDrawerOpen(false)}>
											<CloseIcon fontSize="large" />
										</IconButton>
									</Grid>
								</Grid>
								<Stack spacing={2}>
									<Searchbar />
									<NavLinks pathname={pathname} theme={theme} />
								</Stack>
							</Stack>
						</Box>
					</SwipeableDrawer>
				)}
			</Grid>
		</Box>
	);
};

export default Navbar;
