import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	OutlinedInput,
	TextField,
	Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { authenicate } from '../api';
import AuthContext from '../context/AuthContext';

const LoginPage: React.FC = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [, setToken] = useLocalStorage('_token', '');
	const [, setEmail] = useLocalStorage('_email', '');
	const [, setCompanyName] = useLocalStorage('_company_name', '');

	const { dispatch } = useContext(AuthContext);

	const navigate = useNavigate();

	const handleChange = (
		type: 'username' | 'password',
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (type === 'username') {
			setUsername(e.target.value);
		} else setPassword(e.target.value);
	};

	const goToCreateAccount = () => {
		const REGISTER_URL = 'https://pariplayltd.com/client-area/register/';

		window.location.replace(REGISTER_URL);
	};

	const onSubmitButtonClick = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		try {
			const result = await authenicate(username, password);
			if (typeof result === 'object') {
				dispatch({ type: 'SET_TOKEN', payload: result.token });
				setToken(result.token);
				setEmail(result.email);
				setCompanyName(result.companyName);
				navigate('/');
			} else {
				dispatch({ type: 'REMOVE_TOKEN' });
				setError('Invalid credentials');
			}
		} catch (error) {
			setLoading(false);
			setError((error as any)?.message ?? 'Something went wrong');
		}
	};

	const toggleShowPassword = () => setShowPassword((prev) => !prev);

	return (
		<Box
			sx={{
				position: 'relative',
				backgroundColor: '#00518D',
				padding: '5% 0',
				height: '100vh',
				width: '100vw',
				overflowY: 'auto',
			}}
		>
			<Box>
				<Box
					sx={{
						position: 'fixed',
						top: '0',
						right: '0',
						maxHeight: '50%',
						height: '50vw',
					}}
				>
					<img className="noselect" alt="fusion" height="100%" src="/images/fusion.png" />
				</Box>
				<Box
					sx={{
						position: 'fixed',
						bottom: '0',
						left: '0',
						maxHeight: '50%',
						height: '50vw',
					}}
				>
					<img
						className="noselect"
						alt="fusion"
						height="100%"
						src="/images/ignite.png"
						style={{ display: 'block' }}
					/>
				</Box>
			</Box>

			<Box zIndex="999" position="relative" display="flex" alignItems="center" justifyContent="center">
				<Box maxWidth={{ xs: '300px', sm: '390px' }} display="flex" flexDirection="column">
					<Box my="10px">
						<a href="http://pariplayltd.com/" target="_blank" rel="noreferrer">
							<img className="noselect" width="100%" alt="fusion" src="/images/pariplay-logo-white.png" />
						</a>
					</Box>
					<Box maxWidth="94%">
						<Box my="20px">
							<Typography fontWeight="200" color="white" fontSize="25px">
								Login to our Client Area
							</Typography>
						</Box>
						<Box mt="30px">
							<form onSubmit={onSubmitButtonClick}>
								<Box mb="20px">
									<label>
										<Typography color="white" mb="5px" fontSize="18px">
											Username or Email*
										</Typography>
										<TextField
											onChange={(e) => handleChange('username', e)}
											value={username}
											type="text"
											size="small"
											fullWidth
											aria-describedby="username-helper"
											disabled={loading}
											error={error !== ''}
											required
										/>
									</label>
								</Box>
								<Box>
									<label>
										<Typography color="white" mb="5px" fontSize="18px">
											Password*
										</Typography>
										<OutlinedInput
											onChange={(e) => handleChange('password', e)}
											value={password}
											type={showPassword ? 'text' : 'password'}
											size="small"
											fullWidth
											aria-describedby="password-helper"
											disabled={loading}
											error={error !== ''}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={toggleShowPassword}
														edge="end"
														style={{
															transform: 'scale(0.8)',
														}}
													>
														{showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											}
											required
										/>
									</label>
								</Box>

								{error && (
									<Box my="30px">
										<Typography fontSize="15px" color="white">
											The username, email or password you've entered doesn't match any existing
											account. Please check the details entered above or follow the links below.
										</Typography>
									</Box>
								)}

								<Box my="30px">
									<FormControlLabel
										control={
											<Checkbox
												checked={rememberMe}
												onChange={(e) => setRememberMe(e.target.checked)}
												sx={{ '& .MuiCheckbox-colorPrimary': { color: '#FFFFFF' } }}
											/>
										}
										label={
											<Typography color="white" fontSize="16px">
												Remember Me
											</Typography>
										}
									/>
								</Box>

								<Box>
									<Grid container spacing="10">
										<Grid item xs={12} lg={12}>
											<Button
												sx={{ textTransform: 'capitalize' }}
												fullWidth
												variant="contained"
												type="submit"
												disabled={loading}
											>
												Login
											</Button>
										</Grid>
										<Grid item xs={12} lg={12}>
											<Button
												sx={{ textTransform: 'none', color: '#33ACFF' }}
												onClick={goToCreateAccount}
												type="button"
												fullWidth
												variant="outlined"
												disabled={loading}
											>
												Create an Account
											</Button>
										</Grid>
									</Grid>
								</Box>

								<Box my="30px">
									<a
										style={{ textDecorationColor: 'white' }}
										href="https://pariplayltd.com/pariplay-login/?action=lostpassword"
									>
										<Typography color="white" fontSize="16px">
											Forgot your password?
										</Typography>
									</a>
								</Box>
							</form>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default LoginPage;
